import httpClient from '@/api/httpClient';
const TEAM_API_URL = '/v1/team/';
const TEAMS_API_URL = '/v1/teams/';

const getOwnTeam = () => httpClient.get(TEAM_API_URL).then((resp) => resp.data);

const updateUsers = (newUsers) =>
  httpClient.patch(TEAM_API_URL, { users: newUsers }).then((resp) => resp.data);

const searchUser = (userNameQuery) =>
  httpClient.get(`/api/v1/users/?username=${userNameQuery}`).then((resp) => resp.data);

const getAllTeams = () => httpClient.get(TEAMS_API_URL).then((resp) => resp.data);

export { getOwnTeam, updateUsers, searchUser, getAllTeams };
