import * as teamApi from '@/api/teamApi';
import { memoize } from 'lodash-es';

const getNonOwnedTeams = async () => {
  const allTeams = await teamApi.getAllTeams();
  return allTeams
    .filter((team) => !team.owned_by_target_user && team.team_status !== 'disabled')
    .map((team) => {
      const ownerUsername = team.users.find(
        (user) => user.nextgis_guid === team.owner_id,
      )?.username;
      return { ...team, owner_username: ownerUsername };
    });
};

const memoizedGetNonOwnedTeams = memoize(getNonOwnedTeams);

const memoizedGetOwnTeam = memoize(teamApi.getOwnTeam);

const addMemberToTeam = async (newMember, allMembers) => {
  const usersWithoutOwner = allMembers.slice(1);
  const newTeamUsers = await teamApi.updateUsers([
    ...usersWithoutOwner,
    { nextgis_guid: newMember },
  ]);
  memoizedGetOwnTeam.cache.clear();
  return newTeamUsers;
};
const deleteMemberFromTeam = async (memberToDelete, allMembers) => {
  const newUsers = allMembers.filter(
    (user) => !user.owner && user.nextgis_guid !== memberToDelete.nextgis_guid,
  );
  const newTeamUsers = await teamApi.updateUsers(newUsers);
  memoizedGetOwnTeam.cache.clear();
  return newTeamUsers;
};

export {
  getNonOwnedTeams,
  memoizedGetNonOwnedTeams,
  memoizedGetOwnTeam,
  addMemberToTeam,
  deleteMemberFromTeam,
};
