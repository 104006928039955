import Vue from 'vue';
import VueRouter from 'vue-router';
const Team = () => import('@/pages/Team.vue');
const MemberOfTeams = () => import('@/components/team/MemberOfTeams.vue');
const OwnTeam = () => import('@/components/team/OwnTeam.vue');
const ErrorPage = () => import('@ngservices_front/pages/ErrorPage.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/teammanage',
    name: 'teammanage',
    component: Team,
    redirect: '/teammanage/owner',
    children: [
      {
        path: 'owner',
        name: 'ownTeam',
        component: OwnTeam,
      },
      {
        path: 'member',
        name: 'memberOf',
        component: MemberOfTeams,
      },
    ],
  },
  {
    path: '*',
    name: '404',
    component: ErrorPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     setTimeout(() => tryScrollToAnchor(to.hash, 1000, 0));
  //   }
  //   return savedPosition || { x: 0, y: 0 };
  // },
});

export default router;
