import Vue from 'vue';
import Vuex from 'vuex';
import baseApp from '@ngservices_front/store/modules/baseApp';
import baseUser from '@ngservices_front/store/modules/baseUser';
import webgis from './modules/webgis';
import subscription from './modules/subscription';
import payments from './modules/payments';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    webgis,
    subscription,
    payments,
    // common modules
    baseApp,
    baseUser,
  },
  strict: debug,
});
