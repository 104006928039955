import Vue from 'vue';
import '@ngservices_front/ui-lib';
import main from '@ngservices_front/main';

import NgidBaseLayout from './layouts/NgidBaseLayout.vue'; // remove after moving all pages to vue layouts
import EntryLayout from '@ngservices_front/layouts/EntryLayout.vue';
import BaseLayout from '@ngservices_front/layouts/BaseLayout.vue'; // used for stats

//Pages
import ErrorPage from '@ngservices_front/pages/ErrorPage.vue';
import Profile from '@/pages/Profile.vue';
const ProfileForm = () =>
  import(/* webpackPrefetch: true */ '@/components/profile/ProfileForm.vue');
const ProfileSocialAccounts = () =>
  import(/* webpackPrefetch: true */ '@/components/profile/ProfileSocialAccounts.vue');

import Login from '@/pages/Login.vue';
import Signup from '@/pages/Signup.vue';
// import Consultation from '@/pages/Consultation.vue';
import Subscription from '@/pages/Subscription.vue';
import Software from '@/pages/Software.vue';
import SubscriptionUpdate from '@/pages/SubscriptionUpdate.vue';
import WebgisCreate from '@/pages/WebgisCreate.vue';
import WebgisSettings from '@/pages/WebgisSettings.vue';
import LoginForm from '@/components/account/LoginForm/LoginForm.vue'; // TODO: move to Login.vue
import SignupForm from '@/components/account/SignupForm/SignupForm.vue'; // TODO: move to Signup.vue
import PasswordResetForm from '@/components/account/PasswordResetForm/PasswordResetForm.vue'; // TODO: move to PasswordReset.vue
import Team from '@/pages/Team.vue';
import NgidOnpremise from '@/pages/NgidOnpremise.vue';

import vuetify from './plugins/vuetify';
import { VDialog } from 'vuetify/lib';
import NgSheet from '@ngservices_front/components/ui/NgSheet/NgSheet';
import config from '@/config'; // remove after moving all pages to vue layouts
import store from './store';
import i18n from './i18n';
import router from './router';
import { NoticeSetup } from '@nextgis/vuetify-notice';
import {
  subscribeToGeneralError,
  unsubscribeToGeneralError,
  notifyErrorMessages,
  fireError,
} from '@ngservices_front/services/errorService';
import userApi from './api/userApi';
import { getTimezoneStr } from '@ngservices_front/js/browserUtilities';
import * as subscriptionService from '@/services/subscriptionService';

import { mapState, mapMutations } from 'vuex';

//css
import '@ngservices_front/scss/main.scss';
import './scss/main.scss';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;
Vue.use(NoticeSetup, { vuetify });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  el: '#app',
  components: {
    ErrorPage,
    NgidBaseLayout,
    EntryLayout,
    BaseLayout,
    Profile,
    ProfileForm,
    ProfileSocialAccounts,
    WebgisCreate,
    WebgisSettings,
    Login,
    LoginForm,
    Signup,
    SignupForm,
    Team,
    NgidOnpremise,
    // Consultation,
    PasswordResetForm,
    Subscription,
    SubscriptionUpdate,
    Software,
    VDialog,
    NgSheet,
  },
  computed: {
    ...mapState('baseUser', ['isAuthenticated', 'timezone']),
  },
  watch: {
    isAuthenticated: {
      async handler(val) {
        if (val) {
          this.$store.dispatch('webgis/fetchWebgisData');
          const currentTimezone = getTimezoneStr({ withOffset: true });
          if (this.timezone !== currentTimezone) {
            await userApi.setUserTimezone({ timezone: currentTimezone });
            this.SET_TIMEZONE(currentTimezone);
          }
          try {
            subscriptionService.loadSubscription();
          } catch (e) {
            fireError(e);
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch('baseApp/init', config);
    this.$store.dispatch('baseUser/fetchUserData');
    subscribeToGeneralError({}, notifyErrorMessages);
  },
  beforeDestroy() {
    unsubscribeToGeneralError({}, notifyErrorMessages);
  },
  methods: {
    ...mapMutations('baseUser', ['SET_TIMEZONE']),
    // method for country autocomplete
    dispatchEvent(elementSelector, eventName) {
      const element = document.querySelector(elementSelector);
      const event = new Event(eventName);
      this.$nextTick(() => {
        element.dispatchEvent(event);
      });
    },
  },
});

main({ locale: i18n.locale });
