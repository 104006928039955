<template>
  <v-dialog :value="value" width="480">
    <ng-sheet style="position: relative">
      <ng-button
        small
        text
        icon
        color="grey-dark"
        icon-name="mdi-close"
        @click="close"
        style="position: absolute; top: 4px; right: 4px"
      ></ng-button>
      <template v-if="mode === 'created'">
        <h3 class="mt-0">{{ $t('subscription.dialogs.successCreationTitle') }}</h3>
        <p>
          {{ $t('subscription.yourPlanIs') }} <strong class="text-uppercase">{{ plan }}</strong
          >.<br />
          {{ $t('subscription.nextPaymentDetaield') }}
          <ng-price class="d-inline-block" :amount="price" :currency="currency" simple></ng-price>
          {{ $t('subscription.isSheduled') }} {{ expireAt }}
        </p>
        <p v-html="$t('subscription.dialogs.ifYouHaveQuestions')"></p>
      </template>
      <template v-if="mode === 'creation_failed' || mode === 'updating_failed'">
        <h3 class="mt-0">{{ $t('subscription.dialogs.paymentFailedTitle') }}</h3>
        <p v-html="$t('subscription.dialogs.tryAgainOrContactUs')"></p>
      </template>
      <template v-if="mode === 'updated'">
        <h3 class="mt-0">{{ $t('subscription.dialogs.successUpdatingTilte') }}</h3>
        <p>
          {{ $t('subscription.yourPlanIs') }} <strong class="text-uppercase">{{ plan }}</strong
          ><br />
          {{ $t('subscription.nextPaymentDetaield') }}
          <ng-price class="d-inline-block" :amount="price" :currency="currency" simple></ng-price>
          {{ $t('subscription.isSheduled') }} {{ expireAt }}
        </p>
        <p v-html="$t('subscription.dialogs.ifYouHaveQuestions')"></p>
      </template>
      <template v-if="mode === 'cancel'">
        <h3 class="mt-0">{{ $t('subscription.dialogs.cancelationTitle') }}</h3>
        <p>
          {{ $t('subscription.dialogs.subscriptionWillRemainActive', [expireAt]) }}
        </p>
        <p v-html="$t('subscription.dialogs.ifYouHaveQuestions')"></p>
      </template>
      <template v-if="mode === 'restored'">
        <h3 class="mt-0">{{ $t('subscription.dialogs.restoringTitle') }}</h3>
        <p>
          {{ $t('subscription.nextPaymentDetaield') }}
          <ng-price class="d-inline-block" :amount="price" :currency="currency" simple></ng-price>
          {{ $t('subscription.isSheduled') }} {{ expireAt }}.
        </p>
        <p v-html="$t('subscription.dialogs.ifYouHaveQuestions')"></p>
      </template>
      <template v-if="mode === 'invoice_requested'">
        <h3 class="mt-0">{{ $t('subscription.dialogs.invoiceRequestedTitle') }}</h3>
        <p v-html="$t('subscription.dialogs.invoiceRequestedDescription')"></p>
        <p v-html="$t('subscription.dialogs.ifYouHaveQuestions')"></p>
      </template>
    </ng-sheet>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SubscriptionDialog',
  props: {
    mode: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('subscription', ['plan', 'price', 'currency', 'expireAt', 'interval']),
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped></style>
