<template>
  <div class="inner-menu">
    <router-link
      v-for="(item, index) in items"
      :key="item.to"
      :to="item.to"
      v-slot="{ navigate, isActive }"
    >
      <ng-button
        :text="!isActive"
        :color="isActive ? 'black' : 'grey-dark'"
        depressed
        :class="{ 'white--text': isActive, 'mr-2': index < items.length - 1 }"
        @click="navigate"
      >
        <slot :name="'item.' + item.id" :item="item">
          {{ item.text }}
        </slot>
      </ng-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'InnerMenu',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-menu::v-deep {
  .v-badge__badge {
    inset: auto auto calc(100% - 6px) calc(100% + 2px) !important;
  }
  .v-badge--dot .v-badge__badge {
    width: 6px;
    height: 6px;
  }
}
</style>
