<template>
  <div>
    <app-menu
      :items="menuItemsByUserPlan"
      :active-item="baseApp.currentPage && baseApp.currentPage.id"
      view="vertical"
    >
    </app-menu>
    <div class="my-6 mx-n6">
      <v-divider style="border-color: #efefef"></v-divider>
    </div>
    <a
      href="#"
      onclick="ym(86035883, 'reachGoal', 'onboarding-menu'); Chatra('openChat', true); return false;"
    >
      <ng-sheet
        class="small mx-n3 pr-16 subheading"
        dark
        sm
        height="70"
        color="success-dark"
        :bg-src="onboardingImg"
        bg-position="right -29px bottom -31px"
        bg-size="auto"
        v-html="$t('menu.consultation')"
      ></ng-sheet>
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppMenu from '@ngservices_front/components/AppMenu/AppMenu.vue';
import onboardingImg from '@/assets/img/free_onboarding.svg';

export default {
  name: 'NgIdSidebarMenu',
  components: {
    AppMenu,
  },
  data() {
    return {
      onboardingImg,
      menuItems: [
        {
          id: 'profile',
          link: '/profile',
          text: this.$t('menu.profile'),
          plan: 'any',
        },
        {
          id: 'subscription',
          link: '/subscription',
          text: this.$t('menu.subscription'),
          plan: 'any',
          children: [
            {
              id: 'subscription_update',
              link: '/subscription/update',
            },
          ],
        },
        {
          id: 'ngw_instance_edit',
          link: '/webgis',
          text: this.$t('menu.webgis'),
          plan: 'any',
        },
        {
          id: 'ngw_instance_create',
          link: '/webgis/create',
          text: this.$t('menu.webgis'),
          plan: 'any',
        },
        {
          id: 'software',
          link: '/software',
          text: this.$t('menu.software'),
          plan: 'any',
        },
        {
          id: 'teammanage',
          link: '/teammanage',
          text: this.$t('menu.teams'),
          plan: 'any',
        },
        {
          id: 'myngidonpremises',
          link: '/myngidonpremises',
          text: 'NextGIS ID <nobr>on-premise</nobr>',
          plan: 'any',
        },
      ],
      // secondaryMenuItems: [
      //   {
      //     id: 'consultation',
      //     link: '/consultation',
      //     text: this.$t('menu.consultation'),
      //     plan: 'any',
      //     icon: {
      //       text: 'mdi-help-rhombus',
      //       color: 'primary',
      //     },
      //   },
      // ],
    };
  },
  computed: {
    ...mapState(['baseApp', 'baseUser', 'webgis']),
    ...mapState('subscription', ['plan']),
    compoundPlan() {
      return this.plan || this.baseUser.plan;
    },
    menuItemsByUserPlan() {
      const webgisMenuItemIdToRemove = this.webgis.url
        ? 'ngw_instance_create'
        : 'ngw_instance_edit';
      return this.menuItems.filter((item) => {
        return (
          (item.plan === 'any' || item.plan.indexOf(this.compoundPlan) !== -1) &&
          item.id !== webgisMenuItemIdToRemove
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
