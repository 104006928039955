import 'custom-event-polyfill';
import './dual-switcher.scss';

export default class DualSwitcher {
  constructor(options) {
    this.el = options.el;
    this.state = options.state;
    this.labelLeft = this.el.querySelector('[data-switch-left]');
    this.labelRight = this.el.querySelector('[data-switch-right]');
    this.control = this.el.querySelector('.dual-switcher__control-checkbox');

    this.labelLeft.addEventListener('click', () => this.changeState(0));
    this.labelRight.addEventListener('click', () => this.changeState(1));
    this.control.addEventListener('change', () => {
      const state = this.control.checked ? 1 : 0;
      this.changeState(state);
    });

    this.changeState(this.state);
  }

  changeState(value) {
    const oldValue = this.state;
    this.state = value;
    this.control.checked = this.state === 1;
    this.el.classList.remove('state-' + oldValue);
    this.el.classList.add('state-' + this.state);

    const event = new CustomEvent('switcherChanged', {
      detail: {
        value: this.state,
      },
    });
    this.el.dispatchEvent(event);
  }
}
