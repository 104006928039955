const config = {
  serviceName: 'NextGIS ID',
  email: 'info@nextgis.com',
  withAuthorization: true,
  headerFixed: !window.isBannerShown,
  customUrls: {
    logout: '/silent_logout',
    profile: '/profile',
    //login: define custom login,
    //signup: define custom signup,
  },
};

const AVAILABLE_LOCALES = ['en', 'ru'];

const SUBSCRIPTION_PLAN_DESCRIPTIONS = {
  free: {
    topList: [
      { key: 'subscription.planDescriptions.limitedResources', locales: AVAILABLE_LOCALES },
      {
        key: 'subscription.planDescriptions.users',
        count: 1,
        options: { userCount: 1 },
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.storage',
        options: { storageVolume: '5 GiB' },
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.uploadedFileSize',
        options: ['128 MiB'],
        locales: AVAILABLE_LOCALES,
      },
    ],
    bottomListTitleKey: 'subscription.planDescriptions.freeBottomListTitle',
    bottomList: [
      {
        key: 'subscription.planDescriptions.publicyAvailableResources',
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.basemaps',
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.toolbox',
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.allSoftware',
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.tracking',
        count: 1,
        options: { trackerCount: 1 },
        locales: AVAILABLE_LOCALES,
      },
    ],
  },
  mini: {
    topList: [
      { key: 'subscription.planDescriptions.unlimitedResources', locales: AVAILABLE_LOCALES },
      {
        key: 'subscription.planDescriptions.users',
        count: 1,
        options: { userCount: 1 },
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.storage',
        options: { storageVolume: '10 GiB' },
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.uploadedFileSize',
        options: ['256 MiB'],
        locales: AVAILABLE_LOCALES,
      },
    ],
    bottomListTitleKey: 'subscription.planDescriptions.miniBottomListTitle',
    bottomList: [
      {
        key: 'subscription.planDescriptions.cors',
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.lookupTables',
        locales: AVAILABLE_LOCALES,
      },
    ],
  },
  premium: {
    topList: [
      { key: 'subscription.planDescriptions.unlimitedResources', locales: AVAILABLE_LOCALES },
      {
        key: 'subscription.planDescriptions.users',
        count: 5,
        options: { userCount: '5+' },
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.storage',
        options: { storageVolume: '50+ GiB' },
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.uploadedFileSize',
        options: ['2 GiB'],
        locales: AVAILABLE_LOCALES,
      },
    ],
    bottomListTitleKey: 'subscription.planDescriptions.premiumBottomListTitle',
    bottomList: [
      {
        key: 'subscription.planDescriptions.privacy',
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.toolboxExtended',
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.backups',
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.tracking',
        count: 5,
        options: { trackerCount: '5+' },
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.collector',
        count: 5,
        options: { collectorCount: '5+' },
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.geoservices',
        locales: ['ru'],
      },
      {
        key: 'subscription.planDescriptions.cadastre',
        locales: ['ru'],
      },
      {
        key: 'subscription.planDescriptions.domainName',
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.branding',
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.speed',
        locales: AVAILABLE_LOCALES,
      },
      {
        key: 'subscription.planDescriptions.support',
        locales: AVAILABLE_LOCALES,
      },
    ],
  },
};

const LOCALIZATION = false;

const DISABLED_WEBGIS_STATES = ['disabled', 'wait_for_disable'];
const NONEXISTANT_WEBGIS_STATES = ['error', 'stopped', 'wait_for_create', 'wait_for_start'];

const PROFILE_INDUSTRIES = [
  { value: 'agriculture', textKey: 'industries.agriculture' },
  { value: 'forestry', textKey: 'industries.forestry' },
  { value: 'environment', textKey: 'industries.environment' },
  { value: 'architecture', textKey: 'industries.architecture' },
  { value: 'it', textKey: 'industries.it' },
  { value: 'retail', textKey: 'industries.retail' },
  { value: 'watermanagement', textKey: 'industries.watermanagement' },
  { value: 'hunt', textKey: 'industries.hunt' },
  { value: 'funeral', textKey: 'industries.funeral' },
  { value: 'telecom', textKey: 'industries.telecom' },
  { value: 'realestate', textKey: 'industries.realestate' },
  { value: 'transport', textKey: 'industries.transport' },
  { value: 'cadastre', textKey: 'industries.cadastre' },
  { value: 'utilities', textKey: 'industries.utilities' },
  { value: 'tourism', textKey: 'industries.tourism' },
  { value: 'uav', textKey: 'industries.uav' },
  { value: 'finances', textKey: 'industries.finances' },
  { value: 'manufacturing', textKey: 'industries.manufacturing' },
  { value: 'mining', textKey: 'industries.mining' },
  { value: 'energy', textKey: 'industries.energy' },
  { value: 'history', textKey: 'industries.history' },
  { value: 'healthcare', textKey: 'industries.healthcare' },
  { value: 'emergencies', textKey: 'industries.emergencies' },
  { value: 'elections', textKey: 'industries.elections' },
  { value: 'media', textKey: 'industries.media' },
  { value: 'mapping', textKey: 'industries.mapping' },
  { value: 'datascience', textKey: 'industries.datascience' },
];

const PROFILE_ROLES = [
  { value: 'student', textKey: 'roles.student' },
  { value: 'employee', textKey: 'roles.employee' },
  { value: 'freelancer', textKey: 'roles.freelancer' },
  { value: 'unemployed', textKey: 'roles.unemployed' },
  { value: 'self-employed', textKey: 'roles.selfEmployed' },
  { value: 'intern', textKey: 'roles.intern' },
];

export {
  SUBSCRIPTION_PLAN_DESCRIPTIONS,
  LOCALIZATION,
  DISABLED_WEBGIS_STATES,
  NONEXISTANT_WEBGIS_STATES,
  PROFILE_INDUSTRIES,
  PROFILE_ROLES,
};

export default config;
