<template>
  <ngid-base-layout class="software-page" fluid>
    <v-row>
      <v-col lg="8">
        <h1>{{ $t('software.title') }}</h1>
        <p v-if="compoundPlan === 'free'" v-html="$t('software.descriptionFree')"></p>
        <p v-else v-html="$t('software.descriptionSubscribed')"></p>
      </v-col>
    </v-row>
    <template v-if="!isLoading">
      <div class="software-list d-flex flex-wrap mt-4" v-if="products && products.length > 0">
        <v-row>
          <v-col
            v-for="product in products"
            :key="product.id"
            class="software-list__item"
            cols="6"
            md="3"
          >
            <ng-sheet class="software-list__item-logo mb-4" outlined>
              <img :src="product.iconUrl" class="software-list__item-logo-pic" />
            </ng-sheet>
            <div class="h3 mt-2 mb-1">
              {{ product.name }}
            </div>
            <div v-for="os in osList" :key="os">
              <template v-if="product.installers[`${os}-network`]">
                <a
                  class="small"
                  :href="product.installers[`${os}-network`].link"
                  :target="product.installers[`${os}-network`].external ? '_blank' : '_self'"
                  >{{ $t(`software.download.${os}-network`) }}</a
                >
                <br />
                <!-- 
                <lockable-link
                  v-if="compoundPlan !== 'premium'"
                  :is-locked="true"
                  href="#"
                  :tooltip="$t('software.availableForPremium')"
                  class="small"
                >
                  {{ $t(`software.download.${os}-standalone`) }}
                </lockable-link> -->
              </template>
              <template v-if="product.installers[`${os}-standalone`]">
                <a
                  class="small"
                  :href="product.installers[`${os}-standalone`].link"
                  :target="product.installers[`${os}-standalone`].external ? '_blank' : '_self'"
                  >{{ $t(`software.download.${os}-standalone`) }}</a
                >
              </template>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-alert class="mt-4 d-inline-block" outlined type="error" text v-else>
        Couldn't load software data</v-alert
      >
    </template>
    <v-row v-else>
      <v-col lg="8">
        <div class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col lg="6">
        <ng-sheet color="light-secondary">
          <h3 class="mb-2">{{ $t('software.pluginTitle') }}</h3>
          <p v-html="$t('software.pluginDescription')"></p>
        </ng-sheet>
      </v-col>
    </v-row>
    <slot></slot>
  </ngid-base-layout>
</template>

<script>
import { mapState } from 'vuex';
import NgidBaseLayout from '@/layouts/NgidBaseLayout';
// import LockableLink from '@/components/ui/LockableLink';
import softwareService from '@/services/softwareService';
import { fireError } from '@ngservices_front/services/errorService';

import { OS_LIST } from '@/utils/softwareUtils';

export default {
  name: 'Software',
  components: {
    NgidBaseLayout,
    // LockableLink,
  },
  data() {
    return {
      products: [],
      osList: OS_LIST,
      isLoading: false,
    };
  },
  computed: {
    ...mapState(['baseUser']),
    ...mapState('subscription', ['plan']),
    compoundPlan() {
      return this.plan || this.baseUser.plan;
    },
  },
  async created() {
    this.isLoading = true;
    try {
      this.products = await softwareService.getProductInstallers();
    } catch (error) {
      fireError(error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.software-list {
  margin-top: -24px;

  &__item {
    margin-top: 24px;
    max-width: 275px;

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      max-width: none;
    }

    @media (max-width: 420px) {
      flex-basis: 100%;
    }
  }

  &__item-logo {
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item-logo-pic {
    width: 40%;
    max-width: 80px;
    height: auto;
  }
}
</style>
